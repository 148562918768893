import React from 'react';
import { Radio, Space } from 'antd';
import ReactCountryFlag from 'react-country-flag';
import { RadioBtn } from '../SettingsOverlay/SettingsOverlay.styles';
import { useLanguage } from '@app/hooks/useLanguage';
import { getSetting } from '@app/api/settings.api';
import { setSettingId, setDayColorGood, setDayColorWarn, setDayColorError, setLogoTitle, setLogoUrl, setTextNoteGoodSafety, setTextNoteWarnSafety, setTextNoteErrorSafety, setTextNoteGoodQuality, setTextNoteWarnQuality, setTextNoteErrorQuality, setTextNoteGoodDelivery, setTextNoteWarnDelivery, setTextNoteErrorDelivery, setTextNoteGoodProductivity, setTextNoteWarnProductivity, setTextNoteErrorProductivity } from '@app/services/localStorage.service';

export const LanguagePicker: React.FC = () => {
  const { language, setLanguage } = useLanguage();
  const onChangeLanguage = (e)=>{

    setLanguage(e.target.value);
    getSetting().then((respSetting) =>{
      console.log("getSetting ===>", respSetting);
      if(respSetting.data){              

        let data = respSetting.data;
        setSettingId(data.id);            
        setDayColorGood(data.dayColorGood);
        setDayColorWarn(data.dayColorWarn);
        setDayColorError(data.dayColorError);
        setLogoTitle(data.logoTitle);
        setLogoUrl(data.logoImageUrl);
        
        setTextNoteGoodSafety(data.textNoteGoodSafety);
        setTextNoteWarnSafety(data.textNoteWarnSafety);
        setTextNoteErrorSafety(data.textNoteErrorSafety);

        setTextNoteGoodQuality(data.textNoteGoodQuality);
        setTextNoteWarnQuality(data.textNoteWarnQuality);
        setTextNoteErrorQuality(data.textNoteErrorQuality);

        setTextNoteGoodDelivery(data.textNoteGoodDelivery);
        setTextNoteWarnDelivery(data.textNoteWarnDelivery);
        setTextNoteErrorDelivery(data.textNoteErrorDelivery); 

        setTextNoteGoodProductivity(data.textNoteGoodProductivity);
        setTextNoteWarnProductivity(data.textNoteWarnProductivity);
        setTextNoteErrorProductivity(data.textNoteErrorProductivity);
        setTextNoteGoodSafety(language === 'vi' ? data.textNoteGoodSafety : data.textNoteGoodSafety_en);
        setTextNoteWarnSafety(language === 'vi' ? data.textNoteWarnSafety : data.textNoteWarnSafety_en);
        setTextNoteErrorSafety(language === 'vi' ? data.textNoteErrorSafety: data.textNoteErrorSafety_en);

        setTextNoteGoodQuality(language === 'vi' ? data.textNoteGoodQuality: data.textNoteGoodQuality_en);
        setTextNoteWarnQuality(language === 'vi' ? data.textNoteWarnQuality: data.textNoteWarnQuality_en);
        setTextNoteErrorQuality(language === 'vi' ? data.textNoteErrorQuality: data.textNoteErrorQuality_en);

        setTextNoteGoodDelivery(language === 'vi' ? data.textNoteGoodDelivery: data.textNoteGoodDelivery_en);
        setTextNoteWarnDelivery(language === 'vi' ? data.textNoteWarnDelivery: data.textNoteWarnDelivery_en);
        setTextNoteErrorDelivery(language === 'vi' ? data.textNoteErrorDelivery: data.textNoteErrorDelivery_en); 

        setTextNoteGoodProductivity(language === 'vi' ? data.textNoteGoodProductivity: data.textNoteGoodProductivity_en);
        setTextNoteWarnProductivity(language === 'vi' ? data.textNoteWarnProductivity: data.textNoteWarnProductivity_en);
        setTextNoteErrorProductivity(language === 'vi' ? data.textNoteErrorProductivity: data.textNoteErrorProductivity_en);
      }
      window.location.reload();
    });
  }
  return (
    <Radio.Group defaultValue={language} onChange={onChangeLanguage}>
      <Space direction="vertical">
        <RadioBtn value="en">
          <Space align="center">
            English
            <ReactCountryFlag svg countryCode="GB" />
          </Space>
        </RadioBtn>
        <RadioBtn value="vi">
          <Space align="center">
            Vietnamese
            <ReactCountryFlag svg countryCode="VN" />
          </Space>
        </RadioBtn>
      </Space>
    </Radio.Group>
  );
};
