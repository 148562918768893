import { httpApi } from '@app/api/http.api';


export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
  // totalPage?: number;
}


//===========================================================================

export const getSetting = (): Promise<any> => 
  httpApi.get<any>('/setting/info').then(({ data }) => data    );


export const editSetting  = (req: any): Promise<any> =>
  httpApi.put<any>('/setting/update', { ...req }).then(({ data }) => data);

 
