import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  login,
  logout,
  LoginRequest,
  LogoutRequest,
  signUp,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
  ProfileUpdateRequest,
  setProfile,
  addUser,
  AddUserRequest,
  getProfile,
  
} from '@app/api/auth.api';
import { UserModel } from '@app/domain/UserModel';

import { setUser } from '@app/store/slices/userSlice';
import { deleteToken, deleteUser, persistRefreshToken, persistToken, readToken, deleteRefreshToken, persistUser} from '@app/services/localStorage.service';
import { User } from '@app/components/forms/ControlForm/ControlForm.styles';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

export const doLogin = createAsyncThunk('/oauth2/login', async (loginPayload: LoginRequest, { dispatch }) =>
  login(loginPayload).then((res) => {
    if(res && res.data){
      console.log("login response ", res);
      persistToken(res.data.access_token);
      persistRefreshToken(res.data.refresh_token);
         
      return res.data.access_token;
    }
    
  }),
);

export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk('/oauth2/me/password', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('/oauth2/logout', async (payload: LogoutRequest, { dispatch }) => {
  

  if(payload.refreshToken){
    logout(payload).then((res) => {
      console.log("logout response ", res);
      deleteToken();
      deleteRefreshToken();
      deleteUser();
      dispatch(setUser(null));
      return;
    });
  }  
  
});

export const doSetProfileUpdate = createAsyncThunk('/oauth2/me/profile', async (profileUpdatedata: ProfileUpdateRequest) =>
    setProfile(profileUpdatedata),
);

export const doAddUser = createAsyncThunk('/users', async (addUserReq: AddUserRequest) =>
    addUser(addUserReq),
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
