import React from 'react';
import * as S from './References.styles';
import hitachi_energy_logo from 'assets/hitachi_energy_logo.png';

//import { FacebookOutlined, GithubOutlined, LinkedinOutlined, TwitterOutlined } from '@ant-design/icons';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text>
        Made by{' '}
        <a href="" target="_blank" rel="noreferrer">
        CHDT Solution 
        </a>
        {' '} for VNFF in 2024 &copy;. 
      </S.Text>
      <S.Icons>
        {/* <a href="#" target="_blank" rel="noreferrer">

        </a>
        <a href="#" target="_blank" rel="noreferrer">
        </a>
        <a href="#" target="_blank" rel="noreferrer">
        </a>
        <a href="#" target="_blank" rel="noreferrer">
        </a> */}
        <img src={hitachi_energy_logo} alt="Hitachi Energy" height={36} />
      </S.Icons>
    </S.ReferencesWrapper>
  );
};
