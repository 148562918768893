import { UserModel } from '@app/domain/UserModel';
const avatarImg = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar5.webp';

const testUser = {
  id: 1222,
  fullName: 'Long',
  lastName: 'Tran',
  imgUrl: avatarImg,
  userName: 'longtb',
  email: {
    name: 'longtrb@gmail.com',
    verified: true,
  },
  // phone: {
  //   number: '+18143519459',
  //   verified: true,
  // },
  // sex: 'male',
  // birthday: '01/26/2022',
  // lang: 'en',
  // country: 'GB',
  // city: 'London',
  // address1: '14 London Road',
  // zipcode: 5211,
  
};

export const setSettingId = (title: string): void => {
  localStorage.setItem('settingId', title);
};


export const getSettingId = (): string => {
  return localStorage.getItem('settingId') || '';
};

export const setDayColorGood = (title: string): void => {
  localStorage.setItem('dayColorGood', title);
};


export const getDayColorGood = (): string => {
  return localStorage.getItem('dayColorGood') || '';
};

export const setDayColorWarn = (title: string): void => {
  localStorage.setItem('dayColorWarn', title);
};


export const getDayColorWarn = (): string => {
  return localStorage.getItem('dayColorWarn') || '';
};

export const setDayColorError = (title: string): void => {
  localStorage.setItem('dayColorError', title);
};


export const getDayColorError = (): string => {
  return localStorage.getItem('dayColorError') || '';
};

export const setLogoTitle = (title: string): void => {
  localStorage.setItem('logoTitle', title);
};


export const getTextNoteErrorSafety = (): string => {
  return localStorage.getItem('TextNoteErrorSafty') || '';
};

export const setTextNoteErrorSafety = (title: string): void => {
  localStorage.setItem('TextNoteErrorSafty', title);
};

export const getTextNoteGoodSafety = (): string => {
  return localStorage.getItem('TextNoteGoodSafty') || '';
};

export const setTextNoteGoodSafety = (title: string): void => {
  localStorage.setItem('TextNoteGoodSafty', title);
};

export const getTextNoteWarnSafety = (): string => {
  return localStorage.getItem('TextNoteWarnSafty') || '';
};

export const setTextNoteWarnSafety = (title: string): void => {
  localStorage.setItem('TextNoteWarnSafty', title);
};


export const getTextNoteErrorQuality = (): string => {
  return localStorage.getItem('TextNoteErrorQuality') || '';
};

export const setTextNoteErrorQuality = (title: string): void => {
  localStorage.setItem('TextNoteErrorQuality', title);
};

export const getTextNoteGoodQuality = (): string => {
  return localStorage.getItem('TextNoteGoodQuality') || '';
};

export const setTextNoteGoodQuality = (title: string): void => {
  localStorage.setItem('TextNoteGoodQuality', title);
};

export const getTextNoteWarnQuality = (): string => {
  return localStorage.getItem('TextNoteWarnQuality') || '';
};

export const setTextNoteWarnQuality = (title: string): void => {
  localStorage.setItem('TextNoteWarnQuality', title);
};




export const getTextNoteErrorProductivity = (): string => {
  return localStorage.getItem('TextNoteErrorProductivity') || '';
};

export const setTextNoteErrorProductivity = (title: string): void => {
  localStorage.setItem('TextNoteErrorProductivity', title);
};

export const getTextNoteGoodProductivity = (): string => {
  return localStorage.getItem('TextNoteGoodProductivity') || '';
};

export const setTextNoteGoodProductivity = (title: string): void => {
  localStorage.setItem('TextNoteGoodProductivity', title);
};

export const getTextNoteWarnProductivity = (): string => {
  return localStorage.getItem('TextNoteWarnProductivity') || '';
};

export const setTextNoteWarnProductivity = (title: string): void => {
  localStorage.setItem('TextNoteWarnProductivity', title);
};





export const getTextNoteErrorDelivery = (): string => {
  return localStorage.getItem('TextNoteErrorDelivery') || '';
};

export const setTextNoteErrorDelivery = (title: string): void => {
  localStorage.setItem('TextNoteErrorDelivery', title);
};

export const getTextNoteGoodDelivery = (): string => {
  return localStorage.getItem('TextNoteGoodDelivery') || '';
};

export const setTextNoteGoodDelivery = (title: string): void => {
  localStorage.setItem('TextNoteGoodDelivery', title);
};

export const getTextNoteWarnDelivery = (): string => {
  return localStorage.getItem('TextNoteWarnDelivery') || '';
};

export const setTextNoteWarnDelivery = (title: string): void => {
  localStorage.setItem('TextNoteWarnDelivery', title);
};





export const getLogoTitle = (): string => {
  return localStorage.getItem('logoTitle') || '';
};


export const setLogoUrl = (url: string): void => {
  localStorage.setItem('logoUrl', url);
};


export const getLogoUrl = (): string => {
  return localStorage.getItem('logoUrl') || '';
};


export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};


export const persistRefreshToken = (token: string): void => {
  localStorage.setItem('refreshToken', token);
};

export const readToken = (): string => {
  return localStorage.getItem('accessToken') || '';
};

export const readRefreshToken = (): string => {
  return localStorage.getItem('refreshToken') || '';
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');

  return userStr ? JSON.parse(userStr) : undefined;
};

export const checkRoot = (): boolean => {
  const userStr = localStorage.getItem('user');
  if(userStr){
    return JSON.parse(userStr).isRoot;
  }
  
  return false;
};

export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteRefreshToken = (): void => localStorage.removeItem('refreshToken');
export const deleteUser = (): void => localStorage.removeItem('user');
