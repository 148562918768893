import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readToken, deleteRefreshToken, deleteToken, deleteUser, readUser } from '@app/services/localStorage.service';
// import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// const navigate = useNavigate();
httpApi.interceptors.request.use((config) => {
 

  // let uri = httpApi.getUri([config]);
  //console.log(uri)
  let token = readToken();
  if(token != undefined && token != '' ){
    if(config.url != '/oauth2/login'){
      config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    }else{
      deleteUser();
      deleteToken();
      deleteRefreshToken();
      config.headers = { ...config.headers };
    }    
  }else{
    config.headers = { ...config.headers };
  }
  //console.log(config)
  return config;
});

httpApi.interceptors.response.use(
  undefined, (error: AxiosError) => {
  console.log(error);
  let erroJson = error.toJSON();
  

  if(!error.response){
    console.log('error.response ',error.response)
    notificationController.error({ message: ("Server Error: " + erroJson.message) });
    return {} ;
  }
  if(error.response!.status == 401 ){
    if(readUser()){
      deleteUser();
      deleteToken();
      deleteRefreshToken();
      
      window.location.href ="/auth/login";
      notificationController.info({message: "Hết phiên đăng nhập | Session has been log out"});
    }else{
      notificationController.error({message: "Xác thực thất bại | Authorize failed"});
    }  

  }else{
    console.log('server http error ',error)
    notificationController.error({ message: ("Server Error: " + erroJson.message) });
  }

  return {} 
  
});

export interface ApiErrorData {
  message: string;
}
