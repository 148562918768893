import React from 'react';
import { Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { RadioBtn } from '../SettingsOverlay/SettingsOverlay.styles';
import { useLanguage } from '@app/hooks/useLanguage';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { getSettingId, readToken, readUser, setTextNoteErrorDelivery, setTextNoteErrorProductivity, setTextNoteErrorQuality, setTextNoteErrorSafety, setTextNoteGoodDelivery, setTextNoteGoodProductivity, setTextNoteGoodQuality, setTextNoteGoodSafety, setTextNoteWarnDelivery, setTextNoteWarnProductivity, setTextNoteWarnQuality, setTextNoteWarnSafety } from '@app/services/localStorage.service';
import { Input } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { Form } from 'antd';
import { editSetting, getSetting } from '@app/api/settings.api';
import { setDayColorError, setDayColorGood, setDayColorWarn, setLogoTitle, setLogoUrl, setSettingId, getDayColorError, getDayColorGood, getDayColorWarn } from '@app/services/localStorage.service';
//import {ColorPicker} from 'rc-color-picker';
//import "rc-color-picker/assets/index.css";
import InputColor from 'react-input-color';


export const DashboardDayColorPicker: React.FC = () => {
 
  const language = useLanguage();
  const { t } = useTranslation();

   var dayColorGood =getDayColorGood();
   var dayColorWarn =getDayColorWarn();
   var dayColorError =getDayColorError();
  
  const changeColorGood = async (values: any) => {
    console.log("changeColorGood ", values);
    dayColorGood = values.hex;
  };

  const changeColorWarn = async (values: any) => {
    console.log("changeColorWarn ", values);
    dayColorWarn = values.hex;
  };

  const changeColorError = async (values: any) => {
    console.log("changeColorError ", values);
    dayColorError = values.hex;
  };

  const saveSetting = async (values: any) => {
    try {
      
        var row = values;
        //upload image if change
        
        let req = {id: getSettingId(), dayColorGood: dayColorGood,  dayColorWarn:dayColorWarn,  dayColorError:dayColorError }
       
        
        console.log("setting to update ", req);
        editSetting(req).then((res)=>{
          notificationController.success({message: t('common.success')});
          getSetting().then((respSetting) =>{
            console.log("getSetting ===>", respSetting);
            if(respSetting.data){              

              let data = respSetting.data;
              setSettingId(data.id);            
              setDayColorGood(data.dayColorGood);
              setDayColorWarn(data.dayColorWarn);
              setDayColorError(data.dayColorError);
              setLogoTitle(data.logoTitle);
              setLogoUrl(data.logoImageUrl);
              
              setTextNoteGoodSafety(data.textNoteGoodSafety);
              setTextNoteWarnSafety(data.textNoteWarnSafety);
              setTextNoteErrorSafety(data.textNoteErrorSafety);
    
              setTextNoteGoodQuality(data.textNoteGoodQuality);
              setTextNoteWarnQuality(data.textNoteWarnQuality);
              setTextNoteErrorQuality(data.textNoteErrorQuality);
    
              setTextNoteGoodDelivery(data.textNoteGoodDelivery);
              setTextNoteWarnDelivery(data.textNoteWarnDelivery);
              setTextNoteErrorDelivery(data.textNoteErrorDelivery); 
    
              setTextNoteGoodProductivity(data.textNoteGoodProductivity);
              setTextNoteWarnProductivity(data.textNoteWarnProductivity);
              setTextNoteErrorProductivity(data.textNoteErrorProductivity);
              setTextNoteGoodSafety(language === 'vi' ? data.textNoteGoodSafety : data.textNoteGoodSafety_en);
              setTextNoteWarnSafety(language === 'vi' ? data.textNoteWarnSafety : data.textNoteWarnSafety_en);
              setTextNoteErrorSafety(language === 'vi' ? data.textNoteErrorSafety: data.textNoteErrorSafety_en);

              setTextNoteGoodQuality(language === 'vi' ? data.textNoteGoodQuality: data.textNoteGoodQuality_en);
              setTextNoteWarnQuality(language === 'vi' ? data.textNoteWarnQuality: data.textNoteWarnQuality_en);
              setTextNoteErrorQuality(language === 'vi' ? data.textNoteErrorQuality: data.textNoteErrorQuality_en);

              setTextNoteGoodDelivery(language === 'vi' ? data.textNoteGoodDelivery: data.textNoteGoodDelivery_en);
              setTextNoteWarnDelivery(language === 'vi' ? data.textNoteWarnDelivery: data.textNoteWarnDelivery_en);
              setTextNoteErrorDelivery(language === 'vi' ? data.textNoteErrorDelivery: data.textNoteErrorDelivery_en); 

              setTextNoteGoodProductivity(language === 'vi' ? data.textNoteGoodProductivity: data.textNoteGoodProductivity_en);
              setTextNoteWarnProductivity(language === 'vi' ? data.textNoteWarnProductivity: data.textNoteWarnProductivity_en);
              setTextNoteErrorProductivity(language === 'vi' ? data.textNoteErrorProductivity: data.textNoteErrorProductivity_en);
            }
            window.location.reload();
          });
          
        });
     
    } catch (errInfo) {
      console.log('Failed:', errInfo);
    }
  };
  
  return (
    <Form name="changeDayColorForm" onFinish={saveSetting}>
      
      
       <Radio.Group >
        {/* <Space direction="vertical"> */}
          
          
        <Form.Item>
            
            <InputColor
              initialValue={dayColorGood}
              onChange={changeColorGood}
              placement="left" 
              style={{marginBottom: '-5px', marginRight: '20px'}}/>              
            
            <label >{t('common.goodDay')}</label>
          </Form.Item> 
          
         

          <Form.Item          
            name="dayColorWarn"            
          >
            <InputColor
              initialValue={dayColorWarn}
              onChange={changeColorWarn}
              placement="left"
              style={{marginBottom: '-5px', marginRight: '20px'}}
            /> 
            <label htmlFor="">{t('common.warnDay')}</label>

          </Form.Item>
          
          <Form.Item          
            name="dayColorError"            
          >
            <InputColor
              initialValue={dayColorError}
              onChange={changeColorError}
              placement="left" 
              style={{marginBottom: '-5px', marginRight: '20px'}}
            />
            <label htmlFor="">{t('common.criticalDay')}</label>
          </Form.Item>
          

          <Form.Item>
            <Button type="primary" style={{ marginTop: 14 }} htmlType="submit">
              {t('common.submit')}
              </Button>
          </Form.Item>        
        {/* </Space> */}
      </Radio.Group>
    </Form>  
  );
};
