import React from 'react';
import { DashboardOutlined, UserOutlined, SlidersOutlined, FundProjectionScreenOutlined } from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  isRoot: boolean;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.dashboard',
    key: 'admin',
    url: '/admin',
    icon: <UserOutlined />,
    isRoot: true,
  },
  {
    title: 'common.usersManagement',
    key: 'index',
    url: '/',
    icon: <UserOutlined />,
    isRoot: true,
  },
  {
    title: 'common.workingInProgress',
    key: 'workingInProgress',
    url: '/working-in-progress',
    icon: <DashboardOutlined />,
    isRoot: false,
  },
  {
    title: 'castingAD.castingDemoldingSchedule',
    key: 'schedule',
    url: '/casting-demolding-schedule',
    icon: <SlidersOutlined />,
    isRoot: false,
  },
    {
    title: 'common.waitTimeStation',
    key: 'waitTimeStation',
    url: '/wait-time-of-each-station',
    icon: <FundProjectionScreenOutlined />,
    isRoot: false,
  },
  // {
  //   title: 'common.progressToDeliveryReport',
  //   key: 'progressToDeliveryReport',
  //   url: '/progress-to-delivery-report',
  //   icon: <FundOutlined />,
  //   isRoot: false,
  // },
  // {
  //   title: 'common.progressToDelivery',
  //   key: 'progressToDelivery',
  //   url: '/dashboard/progress-to-delivery',
  //   icon: <StockOutlined />,
  //   isRoot: false,
  // },

  // {
  //   title: 'common.scheduleReport',
  //   key: 'scheduleReport',
  //   url: '/report/casting-demolding-schedule-report',
  //   icon: <ProjectOutlined />,
  //   isRoot: false,
  // },

  // {
  //   title: 'common.overallEquipmentEffectiveness',
  //   key: 'overallEquipmentEffectiveness',
  //   url: '/report/overall-equipment-effectiveness',
  //   icon: <StockOutlined />,
  //   isRoot: false,
  // },
  // {
  //   title: 'common.directLaborProductivity',
  //   key: 'directLaborProductivity',
  //   url: '/report/direct-labor-productivity',
  //   icon: <StockOutlined />,
  //   isRoot: false,
  // },
];
