import React from 'react';
import styled, { css } from 'styled-components';

interface BurgerProps {
  className?: string;
  onClick: () => void;
  isCross: boolean;
}
export const Burger: React.FC<BurgerProps> = ({ className, onClick, isCross }) =>
  isCross ? (
    <svg onClick={onClick} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <title></title>{' '}
        <g id="Complete">
          {' '}
          <g id="arrow-right">
            {' '}
            <g>
              {' '}
              <polyline
                data-name="Right"
                fill="none"
                id="Right-2"
                points="16.4 7 21.5 12 16.4 17"
                
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              ></polyline>{' '}
              <line
                fill="none"
                
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                x1="2.5"
                x2="19.2"
                y1="12"
                y2="12"
              ></line>{' '}
            </g>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  ) : (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000" onClick={onClick} className={className}>
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <title></title>{' '}
        <g id="Complete">
          {' '}
          <g id="align-left">
            {' '}
            <g>
              {' '}
              <polygon
                fill="#ffffff"
                points="12.9 18 4.1 18 4.1 18 12.9 18 12.9 18"
                
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              ></polygon>{' '}
              <polygon
                fill="#ffffff"
                points="20 14 4 14 4 14 20 14 20 14"
                
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              ></polygon>{' '}
              <polygon
                fill="#ffffff"
                points="12.9 10 4.1 10 4.1 10 12.9 10 12.9 10"
                
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              ></polygon>{' '}
              <polygon
                fill="#ffffff"
                points="20 6 4 6 4 6 20 6 20 6"
                
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              ></polygon>{' '}
            </g>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );

export const BurgerIcon = styled(Burger)<{ isCross: boolean }>`
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  stroke:var(--layout-sider-color);
  }
`;
