import React from 'react';
import { Col, Row } from 'antd';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import hitachi_logo_inspire from 'assets/hitachi_logo_inspire.png';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout, toggleSider, isSiderOpened }) => {
  // const leftSide = isTwoColumnsLayout ? (
  //   <S.SearchColumn xl={12} xxl={12}>
  //     <Row justify="space-between">
  //       <Col>{/* <S.GHButton /> */}</Col>
  //       {/* <Col xl={15} xxl={12}>
  //         <HeaderSearch />
  //       </Col> */}
  //     </Row>
  //   </S.SearchColumn>
  // ) : (
  //   <>
  //     <Col>{/* <S.GHButton /> */}</Col>
  //     {/* <Col lg={10} xxl={8}>
  //       <HeaderSearch />
  //     </Col> */}
  //   </>
  // );

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <S.BurgerCol>
          <S.DesktopBurger onClick={toggleSider} isCross={isSiderOpened} />
        </S.BurgerCol>
      </Col>

      <S.ProfileColumn xl={12} xxl={12} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <Row align="middle" justify="end" gutter={[10, 10]}>
          <Col>
            <Row gutter={[{ xxl: 10 }, { xxl: 10 }]}>
              <Col>
                <HeaderFullscreen />
              </Col>

              {/* <Col>
                <NotificationsDropdown />
              </Col> */}

              <Col>
                <SettingsDropdown />
              </Col>
            </Row>
          </Col>

          <Col>
            <ProfileDropdown />
          </Col>
          <Col style={{ marginLeft: 10 }}>
            <img src={hitachi_logo_inspire} alt="Hitachi" height={36} />
          </Col>
        </Row>
      </S.ProfileColumn>
    </Row>
  );
};
