import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from 'hooks/useResponsive';
import logo from 'assets/logo.png';
// import logoDark from 'assets/logo-dark.png';
import { useAppSelector } from '@app/hooks/reduxHooks';
// import { getLogoTitle, getLogoUrl } from '@app/services/localStorage.service';
interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const { tabletOnly } = useResponsive();

  //const theme = useAppSelector((state) => state.theme.theme);

  // const img = getLogoUrl();
  // const logoTitle = getLogoTitle();
  return (
    <>
      {/* <S.SiderLogoDiv>
          
        <h2>Spacer Factory</h2>
        
        
        {tabletOnly && (
          <S.CollapseButton
            shape="circle"
            size="small"
            $isCollapsed={isSiderCollapsed}
            icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
            onClick={toggleSider}
          />
        )}
      </S.SiderLogoDiv> */}
      <>
        <h2 style={{ textAlign: 'center', marginTop: '25px', color: 'var(--text-main-color);' }}>VNFF</h2>
      </>
      {/* <div style={{'display': 'block', marginLeft: 'auto', marginRight: 'auto', width: '80%'}}>
        <img src={logo} alt="Logo" width={180} style={{marginLeft: '10px'}}/>       
      </div>    */}
    </>
  );
};
