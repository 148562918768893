import React from 'react';
//import { Button as BaseButton } from 'antd';
import { FormOutlined, PlayCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { useTranslation } from 'react-i18next';
import { Button } from '@app/components/common/buttons/Button/Button';

export const CreateIssue: React.FC = (props) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { t } = useTranslation();
  return (
    <Button
      href="/issue/info"
      icon={<PlusCircleOutlined />}     
      {...props}
      type="primary" htmlType="submit"
      
    >
      {t('issuepage.createissue')}
    </Button>
  );
};

// const Button = styled(BaseButton)<{ $isDark: boolean }>`
//   color: ${(props) => BASE_COLORS[props.$isDark ? 'white' : 'black']};
//   background: ${(props) => BASE_COLORS[props.$isDark ? 'green' : 'green']};
//   border-radius: 50px;
//   padding-top: 0;
//   padding-bottom: 0;
//   display: flex;
//   align-items: center;

//   &:hover,
//   &:active,
//   &:focus {
//     color: ${(props) => BASE_COLORS[props.$isDark ? 'black' : 'black']};
//     background: ${(props) => BASE_COLORS[props.$isDark ? 'white' : 'white']};
//   }
// `;

// const GithubIcon = styled(FormOutlined)`
//   font-size: 1.5rem;
//   vertical-align: middle;
// `;
