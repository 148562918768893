import { httpApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  fullName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  currentPassword: string;
  newPassword: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  code: number;
  data: {
    access_token: string;
    user: UserModel;
    refresh_token: string;
  };
}

export interface ProfileUpdateRequest {
  username: string;
  fullName: string;
}

export interface LogoutRequest {
  refreshToken: string;
}

export interface AddUserRequest {}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>('/oauth2/login', { ...loginPayload }).then((response) => {
    // console.log('---------', response);
    if (response) {
      return response.data;
    }
    return response;
  });

export const logout = (logoutPayload: LogoutRequest): Promise<{}> =>
  httpApi.post('/oauth2/logout', { ...logoutPayload }).then(
    (response) => {
      // console.log('---------',response);
      if (response) {
        return response.data;
      }
      return response;
    },
    (error) => {
      console.log('oops something went wrong ', error);
    },
  );

export const getProfile = (): Promise<any> => httpApi.get<any>('/oauth2/me/profile', {}).then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('signUp', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('/oauth2/forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.patch<undefined>('/oauth2/me/password', { ...newPasswordData }).then(({ data }) => data);

export const setProfile = (profileReq: ProfileUpdateRequest): Promise<undefined> =>
  httpApi.patch<undefined>('/oauth2/me/profile', { ...profileReq }).then(({ data }) => data);

export const addUser = (addUserReq: AddUserRequest): Promise<undefined> =>
  httpApi.post<undefined>('/users', { ...addUserReq }).then(({ data }) => data);
