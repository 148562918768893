import React from 'react';
import { Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { RadioBtn } from '../SettingsOverlay/SettingsOverlay.styles';
import { useLanguage } from '@app/hooks/useLanguage';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { getSettingId, readToken, readUser, setTextNoteErrorDelivery, setTextNoteErrorProductivity, setTextNoteErrorQuality, setTextNoteErrorSafety, setTextNoteGoodDelivery, setTextNoteGoodProductivity, setTextNoteGoodQuality, setTextNoteGoodSafety, setTextNoteWarnDelivery, setTextNoteWarnProductivity, setTextNoteWarnQuality, setTextNoteWarnSafety } from '@app/services/localStorage.service';
import { Input } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { Form } from 'antd';
import { editSetting, getSetting } from '@app/api/settings.api';
import { setDayColorError, setDayColorGood, setDayColorWarn, setLogoTitle, setLogoUrl, setSettingId } from '@app/services/localStorage.service';

export const LogoTitlePicker: React.FC = () => {
  
  const { t } = useTranslation();
  const {language} = useLanguage();
  const uploadUrl = process.env.REACT_APP_BASE_URL + 'storage/upload';
  var logoId='';
  var logoUrl='';
  
  const beforeUploadHandle = (file: RcFile) => {
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    // if (!isJpgOrPng) {
    //   notificationController.error({message: 'You can only upload JPG/PNG file!'});
    // }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      notificationController.error({message: t('common.validatefileuploadsize')});
    }
    return isLt5M || Upload.LIST_IGNORE ;
  };

  const uploadOnChange = (info: any) => {
    
    if (info.file.status === 'done') {
      console.log('logo uploaded', info.file);
      
      if (info.file && info.file.response && info.file.response.data){
        logoId = info.file.response.data.id;
        logoUrl =  info.file.response.data.url;
      }

    } else if (info.file.status === 'error') {
      console.log(`${info.file} file upload failed.`);
    }
  };


  const fileUploadProps = {
    action: uploadUrl,
    onChange: uploadOnChange,
    beforeUpload: beforeUploadHandle,
    headers: {Authorization:'Bearer '+ readToken()},    
    multiple: false,
  };

  const saveLogoTitleSetting = async (values: any) => {
    try {
      
        var row = values;
        //upload image if change
        
        let req = {id: getSettingId(), logoTitle: row.logoTitle ? row.logoTitle : undefined, logoImageId: logoId, logoImageUrl: logoUrl}
       
        
        console.log("setting to update ", req);
        editSetting(req).then((res)=>{
          notificationController.success({message: t('common.success')});
          getSetting().then((respSetting) =>{
            console.log("getSetting ===>", respSetting);
            if(respSetting.data){
              let data = respSetting.data;
              setLogoTitle(data.logoTitle);
              setLogoUrl(data.logoImageUrl);
              
              setSettingId(data.id);            
              setDayColorGood(data.dayColorGood);
              setDayColorWarn(data.dayColorWarn);
              setDayColorError(data.dayColorError);

              setTextNoteGoodSafety(data.textNoteGoodSafety);
              setTextNoteWarnSafety(data.textNoteWarnSafety);
              setTextNoteErrorSafety(data.textNoteErrorSafety);

              setTextNoteGoodQuality(data.textNoteGoodQuality);
              setTextNoteWarnQuality(data.textNoteWarnQuality);
              setTextNoteErrorQuality(data.textNoteErrorQuality);

              setTextNoteGoodDelivery(data.textNoteGoodDelivery);
              setTextNoteWarnDelivery(data.textNoteWarnDelivery);
              setTextNoteErrorDelivery(data.textNoteErrorDelivery); 

              setTextNoteGoodProductivity(data.textNoteGoodProductivity);
              setTextNoteWarnProductivity(data.textNoteWarnProductivity);
              setTextNoteErrorProductivity(data.textNoteErrorProductivity);

              
              setTextNoteGoodSafety(language === 'vi' ? data.textNoteGoodSafety : data.textNoteGoodSafety_en);
              setTextNoteWarnSafety(language === 'vi' ? data.textNoteWarnSafety : data.textNoteWarnSafety_en);
              setTextNoteErrorSafety(language === 'vi' ? data.textNoteErrorSafety: data.textNoteErrorSafety_en);

              setTextNoteGoodQuality(language === 'vi' ? data.textNoteGoodQuality: data.textNoteGoodQuality_en);
              setTextNoteWarnQuality(language === 'vi' ? data.textNoteWarnQuality: data.textNoteWarnQuality_en);
              setTextNoteErrorQuality(language === 'vi' ? data.textNoteErrorQuality: data.textNoteErrorQuality_en);

              setTextNoteGoodDelivery(language === 'vi' ? data.textNoteGoodDelivery: data.textNoteGoodDelivery_en);
              setTextNoteWarnDelivery(language === 'vi' ? data.textNoteWarnDelivery: data.textNoteWarnDelivery_en);
              setTextNoteErrorDelivery(language === 'vi' ? data.textNoteErrorDelivery: data.textNoteErrorDelivery_en); 

              setTextNoteGoodProductivity(language === 'vi' ? data.textNoteGoodProductivity: data.textNoteGoodProductivity_en);
              setTextNoteWarnProductivity(language === 'vi' ? data.textNoteWarnProductivity: data.textNoteWarnProductivity_en);
              setTextNoteErrorProductivity(language === 'vi' ? data.textNoteErrorProductivity: data.textNoteErrorProductivity_en);
            }
            window.location.reload();
          });
          
        });
     
    } catch (errInfo) {
      console.log('Failed:', errInfo);
    }
  };
  
  return (
    <Form name="changeLogoForm" onFinish={saveLogoTitleSetting}>
      
       <Radio.Group >
        {/* <Space direction="vertical"> */}
          <Form.Item          
            name="logoTitle"
            rules={[{ required: false, message: 'Please input new title!' }]}
          >
            <Input placeholder="Input new title"/>
          </Form.Item>

          <Form.Item >
            <Upload {...fileUploadProps} name="file">           
          
              <Button icon={<UploadOutlined />}>{t('uploads.uploadLogo')}</Button>
            </Upload>  
          </Form.Item>

          <Form.Item>
            <Button type="primary" style={{ marginTop: 16 }} htmlType="submit">
              {t('common.submit')}
              </Button>
          </Form.Item>        
        {/* </Space> */}
      </Radio.Group>
    </Form>  
  );
};
