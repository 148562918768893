import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));

const UserManagerPage = React.lazy(() => import('@app/pages/UserManagerPage'));
const CastingDemoldingSchedulePage = React.lazy(() => import('@app/pages/CastingDemoldingSchedulePage'));
const ProgressToDeliveryReportPage = React.lazy(() => import('@app/pages/ProgressToDeliveryReport'));
const ProgressToDeliveryPage = React.lazy(() => import('@app/pages/ProgressToDelivery'));
const CastingDemoldingSchedulePageReport = React.lazy(() => import('@app/pages/CastingDemoldingSchedulePageReport'));
const WaitTimeStationPage = React.lazy(() => import('@app/pages/WaitTimeStationPage'));
const WorkingInProgressPage = React.lazy(() => import('@app/pages/WorkingInProgressPage'));
const OverallEquipmentEffectivenessPage = React.lazy(() => import('@app/pages/OverallEquipmentEffectivenessPage'));
const DirectLaborProductivityPage = React.lazy(() => import('@app/pages/DirectLaborProductivityPage'));
const AdminPage = React.lazy(() => import('@app/pages/AdminPage'));

const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
//const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));

const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));

const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));

const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/gemba-dashboard';

const UserManager = withLoading(UserManagerPage);
const ProgressToDelivery = withLoading(ProgressToDeliveryPage);
const ProgressToDeliveryReport = withLoading(ProgressToDeliveryReportPage);
const CastingDemoldingSchedule = withLoading(CastingDemoldingSchedulePage);
const CastingDemoldingScheduleReport = withLoading(CastingDemoldingSchedulePageReport);
const WaitTimeStation = withLoading(WaitTimeStationPage);
const WorkingInProgress = withLoading(WorkingInProgressPage);
const OverallEquipmentEffectiveness = withLoading(OverallEquipmentEffectivenessPage);
const DirectLaborProductivity = withLoading(DirectLaborProductivityPage);
const Admin = withLoading(AdminPage);
// UI Components

const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Uploads = withLoading(UploadsPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const DateTimePickers = withLoading(DateTimePickersPage);

const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
//const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={protectedLayout}>
          <Route index element={<UserManager></UserManager>}></Route>
          <Route path="working-in-progress" element={<WorkingInProgress />} />
          <Route path="casting-demolding-schedule" element={<CastingDemoldingSchedule />}></Route>
          <Route path="progress-to-delivery" element={<ProgressToDelivery />}></Route>
          <Route path="progress-to-delivery-report" element={<ProgressToDeliveryReport />}></Route>
          <Route path="casting-demolding-schedule-report" element={<CastingDemoldingScheduleReport />}></Route>
          <Route path="wait-time-of-each-station" element={<WaitTimeStation />}></Route>
          <Route path="overall-equipment-effectiveness" element={<OverallEquipmentEffectiveness />} />
          <Route path="direct-labor-productivity" element={<DirectLaborProductivity />} />
          <Route path="admin" element={<Admin />} />

          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            {/* <Route path="notifications" element={<Notifications />} />
            <Route path="payments" element={<Payments />} /> */}
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          {/* <Route path="security-code" element={<SecurityCodePage />} /> */}
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
